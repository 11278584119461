<template>
  <div></div>
</template>
<script>
export default {
  name: 'TTransactionDownloadFile',

  data() {
    return {
      reportIdDownload: null,
      url: ""
    }
  },

  computed: {
    reportId: {
      set(value) {
        this.reportIdDownload = value
      },
      get() {
        return this.reportIdDownload
      }
    }
  },

  methods: {
    downloadFile() {
      let url = `/v1/admin/reports/${this.reportId}/download`
      this.$http.get(url).then(res => {
        localStorage.removeItem("url_transaction_file_download")
        window.location.href = res.data.report_file_url
        this.$router.push({ name: "transactions" })
      })
      .catch(e => {
        Vue.$log.error('error', e)
      })
    }
  },

  mounted () {
    const { reportId } = this.$route.params
    this.reportId = reportId
    this.downloadFile()
  }
}
</script>
